import React from 'react';
import { bouncy } from 'ldrs'


const BouncyComponent = () => {
    return (
        <div>
            <h1>載入中 <l-bouncy
                size="40"
                speed="1.75"
                color="black"
            ></l-bouncy>
			</h1>
        </div>
    );
};

export default BouncyComponent;
